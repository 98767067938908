import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbyImage } from "gatsby-plugin-image";
import Button from "react-bootstrap/Button";

const TreatmentHero = ({
  img,
  imgAlt,
  heading1,
  text,
  bgColour,
  headingSmall,
  heading2,
  objPos,
  noSmallHeading,
  smallHeadingTopText,
  ctaButton,
}) => {
  const newTopText = smallHeadingTopText || "TREATMENTS";
  return (
    <Container className="px-0 mx-0 mt-5" fluid>
      <Row>
        <Col className="text-center">
          {noSmallHeading !== true && (
            <p className="text-text-secondary jost-bold fs-6">{newTopText}</p>
          )}
          <h1 className=" display-4 mb-5">{heading1}</h1>
        </Col>
      </Row>
      <Row className={`${bgColour} align-items-center`}>
        <Col lg={6}>
          <div className="photo-left-angle mb-4 mb-lg-0">
            <GatsbyImage
              className="photo-left-image"
              image={img}
              alt={imgAlt}
              objectPosition={objPos}
            />
          </div>
        </Col>
        <Col className="px-xl-8 px-4 text-center text-lg-start px-md-5" lg={6}>
          <p className="text-accent jost-bold fs-6">{headingSmall}</p>
          <h2 className="text-text-secondary display-5 mb-4">{heading2}</h2>
          <p className="mb-4 text-start ">{text}</p>
          {ctaButton && ctaButton.url && (
            <Button
              className="text-white py-3 px-5 w-100 w-md-auto"
              variant="accent"
              href={ctaButton?.url}
              target={ctaButton?.target ?? "_blank"}
              rel="noreferrer"
            >
              {ctaButton?.title}
            </Button>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default TreatmentHero;
