import React from "react";
import Col from "react-bootstrap/Col";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { Row } from "react-bootstrap";

const TreatmentCardFacial = ({
	image,
	title,
	tTime,
	imgAlt,
	span,
	results,
	recovery,
	headingHeight,
	extraText,
	cost,
	effects,
	objPos,
	url,
	linkText,
	maintenance,
	Topicalanaesthetic,
	text
}) => {
	const hHeight = headingHeight || "auto";
	return (
		<Col
			style={{ minHeight: "100%" }}
			className="mb-6 mb-xl-0"
			md={6}
			lg={5}
			xl={{ span: span }}
		>
			<div
				style={{
					boxShadow: "0px 3px 50px #00000029",
					background: "none",
					overflow: "hidden",
				}}
				className={` w-100 h-100 feature-box  pb-3 position-relative`}
			>
				<div className={`position-relative   `}>
					<div style={{ zIndex: 0, overflow: "hidden" }} className={`   `}>
						<GatsbyImage
							style={{ height: "250px" }}
							className=""
							image={image}
							alt={imgAlt}
							objectPosition={objPos}
						/>
					</div>
				</div>

				<div
					style={{ width: "calc(100% + 3px)", left: "-1px", zIndex: 2 }}
					className="bg-white  px-4 pb-3 pt-4 position-relative  "
				>
					<h3 style={{ height: hHeight }} className="fs-3 text-center pb-3 ">
						{title}
					</h3>
					{Topicalanaesthetic && (
						<Row>
							<Col>
								<p className="jost-bold">Treatment</p>
							</Col>
							<Col xs={7}>
								<p>Topical anaesthetic</p>
							</Col>
						</Row>
					)}
					{tTime && (
						<Row>
							<Col xs={5}>
								<p className="jost-bold">Treatment time</p>
							</Col>
							<Col xs={7}>
								<p>{tTime}</p>
							</Col>
						</Row>
					)}
					{recovery && (
						<Row>
							<Col xs={5}>
								<p className="jost-bold">Recovery</p>
							</Col>
							<Col xs={7}>
								<p>{recovery}</p>
							</Col>
						</Row>
					)}
					{effects && (
						<Row>
							<Col xs={5}>
								<p className="jost-bold">Effects</p>
							</Col>
							<Col xs={7}>
								<p>{effects}</p>
							</Col>
						</Row>
					)}
					{results && (
						<Row>
							<Col xs={5}>
								<p className="jost-bold">Results</p>
							</Col>
							<Col xs={7}>
								<p>{results}</p>
							</Col>
						</Row>
					)}
					{maintenance && (
						<Row>
							<Col xs={5}>
								<p className="jost-bold">Maintenance</p>
							</Col>
							<Col xs={7}>
								<p>{maintenance}</p>
							</Col>
						</Row>
					)}
					{cost && (
						<Row>
							<Col xs={5}>
								<p className="jost-bold">Cost</p>
							</Col>
							<Col xs={7}>
								<p>{cost}</p>
							</Col>
						</Row>
					)}

					{extraText && <p className="text-center">{extraText}</p>}
					<hr className="bg-main-grey my-3" />
					<Link className="text-secondary-link d-block text-center" to={url}>
						<span className="text-center">{linkText}</span>
					</Link>
				</div>
			</div>
		</Col>
	);
};

export default TreatmentCardFacial;
