import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbyImage } from "gatsby-plugin-image";
import { Button } from "react-bootstrap";

const BenefitsListComponent = ({
  img,
  imgAlt,
  text,
  imgHeight,
  imgPos,
  ctaButton,
}) => {
  return (
    <Container className="px-0 mx-0 h-100" fluid>
      <Row className={`bg-bg h-100 `}>
        <Col
          className={`px-xl-6 px-4  d-none d-lg-block py-5 px-md-5 photo-left-content
						`}
          lg={7}
        >
          {text}
          {ctaButton && ctaButton.url && (
            <Button
              className="text-white py-3 px-5 mt-4 w-100 w-md-auto"
              variant="accent"
              href={ctaButton.url}
              target={ctaButton.target ?? "_blank"}
              rel="noreferrer"
            >
              {ctaButton?.title}
            </Button>
          )}
        </Col>
        <Col
          className={`px-xl-6 px-4 d-lg-none  py-5 py-lg-0 px-md-5 photo-left-content
						`}
          lg={7}
        >
          {text}
          {ctaButton && ctaButton.url && (
            <Button
            className="text-white py-3 px-4 mt-5 w-100 w-md-auto"
              variant="accent"
              href={ctaButton.url}
              target={ctaButton.target ?? "_blank"}
              rel="noreferrer"
            >
              {ctaButton?.title}
            </Button>
          )}
        </Col>
        <Col
          style={{ minHeight: "100%" }}
          className={`d-none  d-lg-block`}
          lg={5}
        >
          <div style={{ minHeight: "100%" }} className=" mb-4 mb-lg-0">
            <GatsbyImage
              style={{ height: imgHeight }}
              image={img}
              alt={imgAlt}
              objectPosition={imgPos}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default BenefitsListComponent;
