import React, { useContext, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";
import { IoIosArrowDown } from "react-icons/io";
import { SafeHtmlParser } from "./safeHtmlParser";

const Faq = ({ heading, faqs }) => {
  return (
    <section className="py-5 py-lg-7 position-relative">
      <div
        style={{ zIndex: "-3" }}
        className=" h-100 w-100 position-absolute"
      ></div>
      <Container>
        <Row className={` `}>
          <Col className="">
            <h2 className=" display-5 mb-4 mb-lg-3">
              {heading || "Frequently Asked Questions"}
            </h2>
          </Col>
        </Row>
        {faqs && faqs.length > 0 && (
          <Row className="align-items-center px-2 mt-3 px-md-0 justify-content-end">
            <Col>
              <Row className="pb-5">
                {faqs.map((faq, index) => (
                  <Col xs={12} className="my-1 mx-0 px-0 ">
                    <Accordion>
                      <Card className="border-0">
                        <Card.Header className="border-0  px-0 p-0 m-0">
                          <ContextAwareToggle
                            eventKey="0"
                            className=" border-0 "
                          >
                            <p className="pb-0 fs-5 mb-0 quicksand-bold text-third">
                              {faq?.faqFields?.question}
                            </p>
                          </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body className="pt-4 ">
                            <Row>
                              <Col className="ps-1 ">
                                <p className="text-black">
                                  <SafeHtmlParser
                                    htmlContent={faq?.faqFields?.answer}
                                  />
                                </p>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <hr style={{ opacity: 1 }} className="bg-accent " />
                    </Accordion>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        )}
      </Container>
    </section>
  );
};

export default Faq;

function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);
  const [turnArrow, setTurnArrow] = useState(false);

  const decoratedOnClick = useAccordionButton(eventKey, () => {
    callback && callback(eventKey);
    setTurnArrow(!turnArrow);
  });

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <Card.Header
      className="border-0"
      style={{
        color: isCurrentEventKey ? "white" : "black",
        backgroundColor: isCurrentEventKey ? "black" : "white",
      }}
      onClick={decoratedOnClick}
    >
      <Container className="pe-0 ps-1" fluid={true}>
        <Row className="align-items-center">
          <Col
            className="d-flex justify-content-start align-items-center ps-0"
            xs={11}
          >
            {children}
          </Col>
          <Col xs={1} className="d-flex px-0  justify-content-end">
            <IoIosArrowDown
              className={`position-relative fs-4 text-primary cheveron  ${
                turnArrow ? "cheveron-down" : ""
              }`}
            />
          </Col>
        </Row>
      </Container>
    </Card.Header>
  );
}
